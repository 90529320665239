<template>
  <div>
    <h2>
      {{CATEGORY.name}} {{ titleComplement }}<slot name="tab-head-commands"></slot>
    </h2>
    <competitors-detailed-list
      :competition_id="competition_id"
      :category_code="category"

      :competitors = "newItems"

      :isLockedForChanges = "isLockedForChanges"
      :isLockedForRegistration = "isLockedForRegistration"
      :isLockedForSelection = "isLockedForSelection"

      :canAddCompetitor = "!editingCompetitorsCategoryIsStarted && (!isLockedForRegistration || !isLockedForSelection)"
      :canEditCompetitor = "(CATEGORY.isPair || CATEGORY.isTeam) && !editingCompetitorsCategoryIsStarted && (!isLockedForRegistration || !isLockedForSelection)"
      :canRemoveCompetitor = "!editingCompetitorsCategoryIsStarted && (!isLockedForRegistration || !isLockedForSelection)"
      :canGiveUp = "isLockedForRegistration && !editingCompetitorsCategoryIsStarted"
      :canChangePresence = "(isLockedForRegistration || isLockedForSelection) && !editingCompetitorsCategoryIsStarted"
      :canWithdraw = "isLockedForSelection && (registrationMode=='S' || registrationMode=='C')"
      :showIsOpen="COMPETITION.isOpen && !editingCompetitorsCategoryIsStarted && !isLockedForRegistration"
      @create = "addCompetitor"
      @edit = "editCompetitor"
      @remove = "removeCompetitor"
      @edit_licensee = "editCompetitorLicensee"
      @refresh = "refresh"
      @licenseeInformationChange = "licenseeInformationChange"
      @isOpenStatus_change = 'competitorOpenStatusChanged'
      @presenceStatusChange = 'competitorPresenceStatusChange'
      @withdrawState_change = 'competitorWithdrawChanged'
      @forfeitState_change = 'competitorForfeitChanged'
      @presenceState_change = 'competitorPresenceChanged'
    >
    </competitors-detailed-list>



    <!-- Dialogue d'ajout d'un compétiteur individuel -->
    <individual-competitor-edit-dialog v-if="CATEGORY && CATEGORY.isIndividual"
      :competition_id = "competition_id"
      :level = "level"
      :category = "category"
      :competitor_id = "selected_competitor_id"
      :creationMode = "competitorDialog_creationMode"
      :search_suggestions = "competitorDialog_suggestions"
      v-model = "showAddEditCompetitorDialog"
      @hide="showAddEditCompetitorDialog = false"
      @ok="addEditCompetitorDialog_ok"
      @search="getIndividualCompetitorsForSearch"
      @competitorCreated="competitorsListChange"
      @competitorModified="competitorsListChange"
    >
    </individual-competitor-edit-dialog>

    <!--<licensee-edit-dialog
                v-model="displayLicenseeDialog"
                :federalNumber = 'editingPilot.federal_number'
                :year = 'editingPilot.year'
                :peopleName = editingPilot.name
            ></licensee-edit-dialog>
-->
<!--
            <individual-competitor-edit-dialog
                v-if="activeCategory.isIndividual"
                v-model="displayCompetitorEditDialog"
                :competition_id="competition_id"
                :level="level_code"
                :category="activeCategory.code"
                :creationMode="competitorEditDialogForCreationMode"
                :search_suggestions="displayCompetitorEditDialogSuggestions"
                @search="getIndividualCompetitorsForSearch"
                @competitorCreated="refreshCurrentTab"
                @competitorModified="refreshCurrentTab"
            >

            </individual-competitor-edit-dialog>
-->
<!--
            <team-competitor-edit-dialog
                v-if="!activeCategory.isIndividual"
                v-model="displayCompetitorEditDialog"
                :competition_id="competition_id"
                :level="level_code"
                :category="activeCategory.code"
                :creationMode="competitorEditDialogForCreationMode"
                :minMemberCount="editingTeam.minMemberCount"
                :maxMemberCount="editingTeam.maxMemberCount"
                :search_suggestions="displayCompetitorEditDialogSuggestions"
                @search="getTeamCompetitorsForSearch"
                @competitorCreated="refreshCurrentTab"
                @competitorModified="refreshCurrentTab"
            >
            </team-competitor-edit-dialog>
-->
            <people-edit-dialog></people-edit-dialog>
    <!-- Dialogue d'ajout d'une paire -->
    <team-competitor-edit-dialog v-if="CATEGORY && CATEGORY.isPair"
      :minMemberCount="2"
      :maxMemberCount="2"
      :competition_id = "competition_id"
      :level = "level"
      :category = "category"
      :competitor_id = "selected_competitor_id"
      :members = "selected_competitor_members"
      :creationMode = "competitorDialog_creationMode"
      :search_suggestions = "competitorDialog_suggestions"
      v-model = "showAddEditCompetitorDialog"
      @hide="showAddEditCompetitorDialog = false"
      @ok="addEditCompetitorDialog_ok"
      @search="getTeamCompetitorsForSearch"
      @competitorCreated="competitorsListChange"
      @competitorModified="competitorsListChange"
      @licenseeInformationChange="licenseeInformationChange"
    >
    </team-competitor-edit-dialog>

    <!-- Dialogue d'ajout d'une équipe -->
    <team-competitor-edit-dialog v-if="CATEGORY && CATEGORY.isTeam"
      :minMemberCount="3"
      :maxMemberCount="8"
      :competition_id = "competition_id"
      :level = "level"
      :category = "category"
      :competitor_id = "selected_competitor_id"
      :members= "selected_competitor_members"
      :creationMode = "competitorDialog_creationMode"
      :search_suggestions = "competitorDialog_suggestions"
      v-model = "showAddEditCompetitorDialog"
      @hide="showAddEditCompetitorDialog = false"
      @ok="addEditCompetitorDialog_ok"
      @search="getTeamCompetitorsForSearch"
      @competitorCreated="competitorsListChange"
      @competitorModified="competitorsListChange"
      @licenseeInformationChange="licenseeInformationChange"
    >
    </team-competitor-edit-dialog>

    <!-- Dialogue de traitement de l'état de validité de la license d'un pilote -->
    <licensee-edit-dialog
      ref="licenceDialog"
      v-model="showEditLicenseeDialog"
      @hide="showEditLicenseeDialog = false"
      @ok="editLicenseeDialog_ok"
      :federalNumber="selectedLicensee"
      :year="selectedLicenceYear"
      :peopleName="selectedLicencePeopleName"
    >
    </licensee-edit-dialog>
  </div>
</template>

<script>
import { RST_COMPETITOR, REF_CATEGORY, RST_COMPETITION, COMPETITOR, PEOPLE } from "@/LocalDB";
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';
import RoundsConfigurationHelper from "@/services/helpers/roundsConfigurationHelper";
import CompetitorsDetailedList from "@/components/CompetitorsDetailedList";
//import IndividualCompetitorEditDialog from "./IndividualCompetitorEditDialog.vue";
//import TeamCompetitorEditDialog from './TeamCompetitorEditDialog.vue';
//import TeamCompetitorEditDialog from './TeamCompetitorEditDialog.vue';
//import LicenseeEditDialog from './LicenseeEditDialog.vue';

import LicenseeEditDialog from '@/components/Dialogs/LicenseeEditDialog.vue';
import IndividualCompetitorEditDialog from '@/components/Dialogs/IndividualCompetitorEditDialog.vue';
import TeamCompetitorEditDialog from '@/components/Dialogs/TeamCompetitorEditDialog.vue';
import PeopleEditDialog from '@/components/Dialogs/PeopleEditDialog.vue';
import { mapGetters } from 'vuex';

export default{
  props:{
    competition_id: { type:String, required: true },
    level: { type:String, required: true},
    category: { type:String, required: true},
    lockedForChanges: { type: Boolean, required: true },
    lockedForRegistration: { type: Boolean, required:true },
    lockedForSelection: { type: Boolean, required: true }
  },
  components:{ CompetitorsDetailedList, IndividualCompetitorEditDialog, TeamCompetitorEditDialog, LicenseeEditDialog, PeopleEditDialog },
  data(){
    return {
      VERBOSE: false,
      competitorTable: false,

      items: [],
      newItems: [],
      showAddEditCompetitorDialog: false,
      selected_competitor_id: null,
      selected_competitor_members: [],
      competitorDialog_creationMode: false,
      competitorDialog_suggestions: [],

      showEditLicenseeDialog: false,
      selectedLicensee: '',
      selectedLicencePeopleName: '',
      selectedLicenceYear: null,

      editingCompetitorsWithMembers:false,

      isLockedForChanges: this.lockedForChanges,
      isLockedForRegistration: this.lockedForRegistration, 
      isLockedForSelection: this.lockedForSelection,

      editingPilot: {
                federal_number: '',
                name: '',
                year: 0
            },
            editingTeam: {
                minMemberCount: 2,
                maxMemberCount: 2
            },
    };
  },
  computed:{
    editingCompetitorsCategoryIsStarted(){
      return RoundsConfigurationHelper.isCategoryStarted(this.competition_id, this.level, this.category);
    },
    isForSimulationPurposeOnly(){
      return true;
    },
    COMPETITION(){
      return RST_COMPETITION.query().where('id', this.competition_id).first();
    },
    CATEGORY(){
      return REF_CATEGORY.query().where('code', this.category).first();
    },
    registrationMode(){
      return this.COMPETITION.registrationMode;
    },
    titleComplement(){
      const registrationMode = this.COMPETITION.registrationMode;
      switch(registrationMode){
        case "S":
          return " sélectionnés";
        case "R":
          return " inscrits";
        case "A":
          return " pré-inscrits";
      }
      return "";
    },
    activeCategory(){
      return this.CATEGORY;
    }
  },
  methods:{
    ...mapGetters("config", ["cfgCompetitionWizard_showPresentCompetitorsOnly"]),
    async refresh(){
      var presentsOnly = this.cfgCompetitionWizard_showPresentCompetitorsOnly();
      await this.$showRefresher();
      if(this.VERBOSE) console.log('refresh for ', this.competition_id, this.level, this.category);
      var ok = await CompetitionsConfigurationHelper.refreshLevelCategoryCompetitors(this.competition_id, this.level, this.category);
      if(this.competitorTable){
        this.editingCompetitorsWithMembers = !this.CATEGORY.isIndividual;
        this.items = RST_COMPETITOR.query()
            .where("competition_id", this.competition_id)
            .where("level", this.level)
            .where("category", this.category)
            .where(c => c.isPresent || !presentsOnly)
            .orderBy("subscription_order")
            .get()
            .map(mapped => {
              var hasAtLeastOneNote = mapped.hasAtLeastOneNote();
              var competitor = mapped.COMPETITOR;
              if(this.VERBOSE) ('refresh mapped competitor', competitor);
              var people = competitor.isIndividual ? competitor.PEOPLE : null;
              if(competitor.isIndividual && people == null)
              {
                people = PEOPLE.query().where('id', competitor.people_id).first();
                if(people == null)
                if(this.VERBOSE) console.log('pb. competitor/people', {mapped: mapped, competitor: competitor, people: people});
              }
              var licensee = people != null ? people.LICENSEE : null;
              if(this.VERBOSE) console.log('people/licensse', people, licensee);
              var members = this.editingCompetitorsWithMembers 
                          ? mapped.MEMBERS.map(m => { var id = m.id;
                              var people_id = m.people_id;
                              return { ...m, ...m.PEOPLE, LICENSEE: m.LICENSEE, id: id, people_id: people_id }})
                          : null
              var complement = {
                isOpen: mapped.isOpen,
                canChangeIsOpenStatus: !hasAtLeastOneNote,
                canWithdraw : !hasAtLeastOneNote,
                canBeRemoved : !hasAtLeastOneNote,
                canGiveUp : !hasAtLeastOneNote,
                canChangePresence : !hasAtLeastOneNote,
                canDelete : !hasAtLeastOneNote,
                canEdit : true,
                firstname: competitor.isIndividual ? people.firstname : null,
                lastname: competitor.isIndividual ? people.lastname : null,
                federal_number : competitor.isIndividual ? mapped.COMPETITOR.PEOPLE.federal_number : null,
                isWithdrawn: mapped.isWithdrawn,
                isAbsent: mapped.isAbsent,
                isForfeited: mapped.isForfeited,
                PEOPLE: people,
                LICENSEE: licensee,
                members: members,
              };
              return { ...competitor, ...complement};
            });
            if(this.VERBOSE) console.log('refresh -> items', this.items);
      }
      this.newItems = RST_COMPETITOR.query()
          .where("competition_id", this.competition_id)
          .where("level", this.level)
          .where("category", this.category)
          .where(c => c.isPresent || !presentsOnly)
          .orderBy("subscription_order")
          .get();
      var o = 1;
      this.newItems.forEach(c => { c.subscription_order = o++; } )
      this.$emit('dataSynchronisationStateChange', ok);
      this.$hideRefresher();
    },
    async competitorPresenceStatusChange(competitor, status){
      this.$showSaver();
      var competitor_id = competitor.competitor_id;
      try{
        var success = await CompetitionsConfigurationHelper.change_competitor_presenceStatusAsync(this.competition_id, competitor_id, status);
        this.toastAsyncSaved(success, 'Etat de présence du compétiteur modifiée');
      }catch(error){
        this.toastError("Echec de la modification de l'état de présence du compétiteur") ;
      }
      this.$hideSaver();
      await this.refresh();
      this.competitorsListChange();
    },
    create(){
        var curCategory = this.activeCategory;
        if(this.VERBOSE) console.log('create curCategory', curCategory);
        if(curCategory.isIndividual){
            this.editingPilot = {
                federal_number: '',
                name: '',
                year: 0
            };
        } else {
            this.editingTeam.minMemberCount = this.activeCategory.isPair ? 2 : 3;
            this.editingTeam.maxMemberCount = this.activeCategory.isPair ? 2 : 6;
        }
        this.displayCompetitorEditDialogTitle = this.$tc("pages.competitors.edit-dialog.add-title-byMinNumberOfFollowers", this.nbMinFollowers);
        this.competitorEditDialogForCreationMode = true;
        this.displayCompetitorEditDialog = true;
    },
    getIndividualCompetitorsForSearch(search){
        if(search.length < 2) return;
        search = search.toLowerCase();
        const curCategory = this.activeCategory;
        var alreadySetCompetitorsPeopleIds = RST_COMPETITOR.query()
            .where('competition_id', this.competition_id)
            .where('category', curCategory.code)
            .where(c => c.name.toLowerCase().includes(search))
            .get()
            .map(c => c.people_id);

        var suggestions = COMPETITOR.query()
            .where('competition_type', this.COMPETITION.competition_type)
            .where('category', curCategory.code)
            .where(c => c.name.toLowerCase().includes(search))
            .where(c => !alreadySetCompetitorsPeopleIds.includes(c.people_id))
            .get()
            .map(item => {
                alreadySetCompetitorsPeopleIds.push(item.people_id);
                var mapped = item;
                mapped.priority = item.category == curCategory.code ? 1 : 3;
                mapped.priorityMatch = `#${mapped.priority}#`;
                mapped.matches = item.category == curCategory.code ? '**' : '*';
                mapped.forSuggestion = `${mapped.priorityMatch} ${item.name} ` + ((item.isFederal == true) ?  ' - ' + item.PEOPLE.federal_number : '');
                return mapped;
            }).concat(
                COMPETITOR.query()
                    .where('competition_type', this.COMPETITION.competition_type)
                    .where(c => c.isIndividual)
                    .where(c => c.category != curCategory.code)
                    .where(c => c.name.toLowerCase().includes(search))
                    .where(c => !alreadySetCompetitorsPeopleIds.includes(c.people_id))
                    .get()
                    .map(item => {
                        alreadySetCompetitorsPeopleIds.push(item.people_id);
                        var mapped = item;
                        mapped.priority = 3;
                        mapped.priorityMatch = `#${mapped.priority}#`;
                        mapped.matches = '*';
                        mapped.forSuggestion = `${mapped.priorityMatch} ${item.name} ` + ((item.isFederal == true) ?  ' - ' + item.PEOPLE.federal_number : '');
                        return mapped;
                    })
            ).concat(
                PEOPLE.query()
                    .where(p => p.name.toLowerCase().includes(search))
                    .where(p => !alreadySetCompetitorsPeopleIds.includes(p.id))
                    .get()
                    .map(people => {
                        var priority = 5;
                        var priorityMatch = `#${priority}#`;
                        var mapped = {
                              id: null,
                              name: people.name,
                              shortname: people.shortname,
                              federal_number: people.federalNumber,
                              firstname: people.firstname,
                              lastname: people.lastname,
                              sexe: people.sexe,
                              people_id: people.id,
                              isIndividual: true,
                              PEOPLE: people,
                              priority: priority,
                              priorityMatch: priorityMatch,
                              matches: '',
                              forSuggestion: `${priorityMatch} ${people.name} ` + ((people.isFederal == true) ? ' - ' + people.federal_number : ''),
                          };
                          return mapped;
                    })
            );
        suggestions.sort((a,b) => { var eqdif = a.priority - b.priority; return eqdif == 0 ? a.name.localeCompare(b.name) : eqdif} );
        //this.displayCompetitorEditDialogSuggestions = suggestions;
        this.competitorDialog_suggestions = suggestions;
    },
    getTeamCompetitorsForSearch(search){
        if(search.length < 2) return;
        search = search.toLowerCase();
        const curCategory = this.activeCategory;
        var alreadySetCompetitorsIds = RST_COMPETITOR.query()
            .where('competition_id', this.competition_id)
            .where('category', curCategory.code)
            .where(c => c.name.toLowerCase().includes(search))
            .get()
            .map(c => c.competitor_id);

        var suggestions = COMPETITOR.query()
            .where('competition_type', this.COMPETITION.competition_type)
            .where('category', curCategory.code)
            .where(c => c.name.toLowerCase().includes(search))
            .where(c => !alreadySetCompetitorsIds.includes(c.competitor_id))
            .orderBy('name')
            .get()
            .map(item => {
                alreadySetCompetitorsIds.push(item.competitor_id);
                var mapped = item;
                mapped.priority = 1;
                mapped.priorityMatch = `#${mapped.priority}#`;
                mapped.matches = '**';
                mapped.forSuggestion = `${mapped.priorityMatch} ${item.name} `;
                return mapped;
            });
        //this.displayCompetitorEditDialogSuggestions = suggestions;
        this.competitorDialog_suggestions = suggestions;
    },

    addCompetitor(){
      this.competitorDialog_creationMode = true;
      this.selected_competitor_id = null;
      this.showAddEditCompetitorDialog = true;
    },

    editCompetitor(competitor){
      const arg = competitor;
      if(this.VERBOSE) console.log('editCompetitor', arg);
      competitor = typeof(competitor) == 'object' ? competitor : RST_COMPETITOR.query().where('id', competitor).first;
      if(this.VERBOSE) console.log('editCompetitor bis', competitor);
      this.competitorDialog_creationMode = false;
      this.selected_competitor_id = competitor;
      this.selected_competitor_members = competitor.members && Array.isArray(competitor.members) ? competitor.members.map(a => a) : [];
      if(this.VERBOSE) console.log('editCompetitor', this.selected_competitor_id, this.selected_competitor_members, );
      this.showAddEditCompetitorDialog = true;
    },
    editCompetitorLicensee(competitor){
      if(this.VERBOSE) console.log('editCompetitorLicensee', { competitor: competitor, type: typeof(competitor), instanceOfPeople: (competitor instanceof(PEOPLE)) });
      var people = (competitor instanceof(PEOPLE)) ? competitor : PEOPLE.query().where("id", competitor.people_id).first();
      this.selectedLicensee = people.federalNumber;
      this.selectedLicencePeopleName= people.name;
      this.selectedLicenceYear = this.COMPETITION.year;
      this.showEditLicenseeDialog = true;
    },
    async removeCompetitor(competitor){
      if(this.VERBOSE) console.log('removeCompetitor', competitor);
      this.$showSaver();
      var competitor_id = typeof(competitor) == 'object' ? competitor.id : competitor;
      var success = await CompetitionsConfigurationHelper.dettach_competitor_Async(this.competition_id, competitor_id, this.level, this.category);
      this.toastAsyncSaved(success, 'Compétiteur supprimé de la compétition.');
      this.$hideSaver();
      await this.refresh();
      this.competitorsListChange();
    },
    competitorsListChange(){
      if(this.VERBOSE) ('competitorsListChange');
      this.$emit('competitorsListChange');
    },
    async competitorOpenStatusChanged(competitor){
      await this.showSaver();
      var success = await CompetitionsConfigurationHelper.changeCompetitorIsOpenStatusAsync(this.competition_id, competitor.id, competitor.isOpen);
      this.toastAsyncSaved(success, competitor.isOpen ? 'Compétiteur marqué comme participant en mode "open"' : 'Compétiteur marqué pour concourrir en mode "classé"');
      this.hideSaver();
      await this.refresh();
      this.presentCompetitorsChange();
   },
    async competitorWithdrawChanged(competitor){
      await this.$showSaver()
      var success = await CompetitionsConfigurationHelper.changeCompetitor_withdraw_Async(this.competition_id, competitor.id, competitor.isWithdrawn);
      this.toastAsyncSaved(success, competitor.isWithdrawn ? 'Compétiteur marqué comme "désisté".' : 'Compétiteur marqué comme "inscrit".');
      this.$hideSaver();
      await this.refresh();
      this.presentCompetitorsChange();
    },
    async competitorForfeitChanged(competitor){
      await this.$showSaver()
      var success = await CompetitionsConfigurationHelper.changeCompetitor_forfeit_Async(this.competition_id, competitor.id, competitor.isForfeited);
      this.toastAsyncSaved(success, competitor.isForfeited ? 'Compétiteur marqué comme "Forfait".' : 'Compétiteur marqué comme "Participant".');
      this.$hideSaver();
      await this.refresh();
      this.presentCompetitorsChange();
    },
    async competitorPresenceChanged(competitor){
      if(this.VERBOSE) ('competitorPresenceChanged', competitor);
      await this.$showSaver()
      var success = await CompetitionsConfigurationHelper.changeCompetitor_presence_Async(this.competition_id, competitor.id, competitor.isAbsent);
      this.toastAsyncSaved(success, competitor.isAbsent ? 'Compétiteur marqué comme "Absent".' : 'Compétiteur marqué comme "Présent".');
      this.$hideSaver();
      await this.refresh();
      this.presentCompetitorsChange();
    },
    async licenseeInformationChange(licensee){
      if(this.VERBOSE) console.log('licenseeInformationChange', licensee);
      await this.$showSaver();
      var result = await CompetitionsConfigurationHelper.changeLicenceInfoAsync(licensee.federal_number, licensee.year, licensee.isValid, licensee.isCompetitor, licensee.isMedicalCertificateValid);
      this.$hideSaver();
      this.toastAsyncSaved(result, "Informations de licence mises à jour");
    },
    presentCompetitorsChange(){
      this.$emit('presentCompetitorsChange');
    },
    async addEditCompetitorDialog_ok(){
      await this.refresh();
    },
    async editLicenseeDialog_ok(){
      await this.refresh();
    },
    displayLockState(step){
      if(this.VERBOSE) 
        console.log(`lock state ${step}`, { 
          isLockedForChanges: this.isLockedForChanges, 
          lockedForChanges: this.lockedForChanges,
          isLockedForRegistration: this.isLockedForRegistration,
          lockedForRegistration: this.lockedForRegistration, 
          isLockedForSelection: this.isLockedForSelection,
          lockedForSelection: this.lockedForSelection,
        });
    }
  },
  async mounted(){
    await this.refresh();
    if(this.VERBOSE) this.displayLockState('mounted');
  },
  watch:{
    lockedForChanges: function(newValue){
      this.isLockedForChanges = newValue;
      if(this.VERBOSE) this.displayLockState('lockedForChanges');
    },
    lockedForRegistration: function(newValue){
      this.isLockedForRegistration = newValue;
      if(this.VERBOSE) this.displayLockState('lockedForRegistration');
    },
    lockedForSelection: function(newValue){
      this.isLockedForSelection = newValue;
      if(this.VERBOSE) this.displayLockState('lockedForSelection');
    }
  }
}
</script>
