<script>
import CardListTemplate from './Lists/CardListTemplate.vue';
import FederalNumber from './Panels/FederalNumber.vue';
import { RST_COMPETITION } from '../LocalDB';
import { REF_CATEGORY } from '../LocalDB';
import { mapGetters, mapMutations } from 'vuex';

export default{
    components:{ CardListTemplate, FederalNumber },
    props: {
        competition_id: { type: String, required: true },
        category_code: { type: String, required: true },
        canAddCompetitor: { type:Boolean, default: false },
        canRemoveCompetitor: { type: Boolean, default: false },
        canEditCompetitor: { type: Boolean, default:false},
        competitors: { type: Array, default: new Array() },
        showIsOpen: { type: Boolean, default: false },
        canChangePresence: { type: Boolean, default: false },
        canGiveUp: { type: Boolean, default: false },
        canWithdraw: { type: Boolean, default: false },
        isLockedForChanges: {type: Boolean, required: true },
        isLockedForRegistration: { type: Boolean, required: true},
        isLockedForSelection: { type: Boolean, required: true },
    },
    data(){
        return {
            VERBOSE: false,

            d_categoryCode: this.category_code,
            d_canAddCompetitor: this.canAddCompetitor,
            d_canRemoveCompetitor: this.canRemoveCompetitor,
            d_canEditCompetitor: this.canEditCompetitor,
            d_competitors: this.competitors,
            d_showIsOpen: this.isOpen,
            d_canChangePresence: this.canChangePresence,
            d_canGiveUp: this.canGiveUp,
            d_canWithdraw: this.canWithdraw,
            d_isLockedForChanges: this.isLockedForChanges,
            d_isLockedForRegistration: this.isLockedForRegistration,
            d_isLockedForSelection: this.isLockedForSelection,
            d_showPresenceOptions: false,
        };
    },
    computed:{
        ...mapGetters("config", ["cfgCompetitionWizard_showPresentCompetitorsOnly"]),
        COMPETITION(){
            return RST_COMPETITION.query().where('id', this.competition_id ).first();
        },
        CATEGORY(){
            if(this.VERBOSE) console.log('CATEGORY', this.d_categoryCode);
            return REF_CATEGORY.query().where('code', this.d_categoryCode ).first();
        },
        d_isFederal(){ return this.COMPETITION.isFederal; },
        nbMinFollowers(){
            var cat = this.CATEGORY;
            return cat.isIndividual ? 0 : (cat.isPair ? 1 : 2);
        },
        showPresentOnly:{
            get(){ return this.cfgCompetitionWizard_showPresentCompetitorsOnly;},
            set(value) {
                this.setCfgCompetitionWizard_showPresentCompetitorsOnly(value); 
                this.refresh();
            }
        }
    },
    methods:{
        ...mapMutations("config", ["setCfgCompetitionWizard_showPresentCompetitorsOnly"]),
        create(){ this.$emit('create'); },
        refresh() { this.$emit('refresh'); },
        remove(id) { this.$emit('remove', id); },
        edit(competitor) { this.$emit('edit', competitor); },
        edit_licensee(people){ this.$emit('edit_licensee', people); },

        canCompetitorBeRemoved(competitor){ 
            var isSelected = (competitor.isSelected == true)
            if(this.VERBOSE) console.log('canCompetitorBeRemoved', { competitor: competitor, isSelected: isSelected, d_isLockedForChanges : this.d_isLockedForChanges, d_isLockedForRegistration : this.d_isLockedForRegistration, d_isLockedForSelection: this.d_isLockedForSelection });
            if(this.d_isLockedForChanges) return false;
            if(this.d_isLockedForRegistration && !isSelected) return false;
            if(this.d_isLockedForSelection && isSelected) return false;
            return !competitor.hasAtLeastOneNote();
        },

        canCompetitorBeEdited(competitor){
            var isSelected = (competitor.isSelected == true)
            if(this.VERBOSE) console.log('canCompetitorBeEdited', { competitor: competitor, isSelected: isSelected, d_isLockedForChanges : this.d_isLockedForChanges, d_isLockedForRegistration : this.d_isLockedForRegistration, d_isLockedForSelection: this.d_isLockedForSelection });
            if(this.d_isLockedForChanges) return false;
            if(this.d_isLockedForRegistration && !isSelected) return false;
            if(this.d_isLockedForSelection && isSelected) return false;
            return !competitor.hasAtLeastOneNote();
        },
        displayLockState(step){
            if(this.VERBOSE) 
                console.log(`lock state ${step}`, { 
                    d_isLockedForChanges: this.d_isLockedForChanges, 
                    isLockedForChanges: this.isLockedForChanges,
                    d_isLockedForRegistration: this.d_isLockedForRegistration,
                    isLockedForRegistration: this.isLockedForRegistration, 
                    d_isLockedForSelection: this.d_isLockedForSelection,
                    isLockedForSelection: this.isLockedForSelection,
                });
        },
        setCompetitorPresent(competitor){
            //competitor.isAbsent=false; 
            //competitor.isForfeited=false; 
            //competitor.isWithdrawn=false; 
            this.$emit('presenceStatusChange', competitor, { isAbsent: false, isForfeited: false, isWithdrawn: false} );
            this.d_showPresenceOptions=false;
        },
        setCompetitorAbsent(competitor){
            this.$emit('presenceStatusChange', competitor, { isAbsent: true, isForfeited: false, isWithdrawn: false} );
            //competitor.isAbsent = true;
            this.d_showPresenceOptions = false;
        },
        setCompetitorForfeited(competitor){
            this.$emit('presenceStatusChange', competitor, { isAbsent: false, isForfeited: true, isWithdrawn: false} );
//            competitor.isForfeited = true;
  //          competitor.isWithdrawn = false;
            this.d_showPresenceOptions = false;
        },
        async setCompetitorWithdrawn(competitor){
            this.$emit('presenceStatusChange', competitor, { isAbsent: false, isForfeited: false, isWithdrawn: true} );
 //           competitor.isForfeited = false;
 //           competitor.isWithdrawn = true;
            this.d_showPresenceOptions = false;
        }
    },
    mounted(){
        if(this.VERBOSE) this.displayLockState('mounted');
    },
    watch: {
        competitors: function(newValue){ 
            if(this.VERBOSE) console.log('watch competitors', newValue); 
            this.d_competitors = newValue || []; 
        },
        canAddCompetitor: function(newValue) { if(newValue != this.d_canAddCompetitor) this.d_canAddCompetitor = newValue; },
        canRemoveCompetitor: function(newValue) { if(newValue != this.d_canRemoveCompetitor) this.d_canRemoveCompetitor = newValue; },
        canEditCompetitor: function(newValue) { if(newValue != this.d_canEditCompetitor) this.d_canEditCompetitor = newValue; },
        category_code: function(newValue) { if(newValue != this.d_categoryCode) this.d_categoryCode = newValue; },
        showIsOpen: function(newValue){ if(newValue != this.d_showIsOpen) this.d_showIsOpen = newValue; },
        canChangePresence: function(newValue){ if(newValue != this.d_canChangePresence) this.d_canChangePresence = newValue; },
        canGiveUp: function(newValue){ if(newValue != this.d_canGiveUp) this.d_canGiveUp = newValue; },
        canWithdraw: function(newValue){ if(newValue != this.d_canWithdraw) this.d_canWithdraw = newValue; },
        isLockedForChanges: function(newValue){ 
            if(newValue != this.d_isLockedForChanges) this.d_isLockedForChanges = newValue;
            if(this.VERBOSE) this.displayLockState('isLockedForChanges');
        },
        isLockedForRegistration: function(newValue){ 
            if(newValue != this.d_isLockedForRegistration) this.d_isLockedForRegistration = newValue;
            if(this.VERBOSE) this.displayLockState('isLockedForRegistration');
        },
        isLockedForSelection: function(newValue){ 
            if(newValue != this.d_isLockedForSelection) this.d_isLockedForSelection = newValue;
            if(this.VERBOSE) this.displayLockState('isLockedForSelection');
        },
    }
}
</script>

<template>
    <card-list-template>
        <template #header-commands-left>
            <slot name="header-commands-left">
                <b-button size="sm" @click="create" v-if="d_canAddCompetitor">{{ $tc(`pages.competition-admin.competitors.add-${isLockedForSelection ? "" : "qualified-"}byMinNumberOfFollowers`, nbMinFollowers) }}</b-button>
            </slot>
            <b-check switch v-model="showPresentOnly">{{ $t('pages.competition-admin.competitors.hideNonParticipant') }}</b-check>
        </template>
        <template #header-commands-right>
            <slot name="header-commands-right">
                <b-button size="sm" @click="refresh">{{ $t('pages.competition-admin.competitors.refresh') }}</b-button>
            </slot>
        </template>

        <b-card v-for="competitor in d_competitors" :key="competitor.id" class="competitor" :class="{'open': competitor.isOpen, 'absent': competitor.isAbsent, 'forfeited': competitor.isForfeited, 'withdrawn': competitor.isWithdrawn}">
            <div style="display:flex; justify-content: space-between;">
                <div>
                    <b-badge style="margin-right: 5px;">{{  competitor.subscription_order }}</b-badge>
                    <b class="name">{{ competitor.name }}</b>
                    <span v-if="competitor.CATEGORY.isIndividual">&nbsp;</span>
                    <federal-number v-if="competitor.CATEGORY.isIndividual" should-be-competitor :is-federal="COMPETITION.isFederal" v-model="competitor.LICENSEE" force-edit @edit="edit_licensee(competitor.PEOPLE)"></federal-number>
                </div>
                <b-checkbox v-if="d_showIsOpen && !competitor.isSelected" size="sm" switch v-model="competitor.isOpen" @change="$emit(isOpenStatus_change(competitor))">"open"</b-checkbox>
                <div style="font-size:1.2rem;">
                    <b-badge class="qualified" v-if="competitor.isQualified">Qualifié</b-badge>
                    <b-badge class="open" v-if="!d_showIsOpen && competitor.isOpen">"Open"</b-badge>

                    <b-badge class="presence-badge selector present" v-if="d_showPresenceOptions && !competitor.isPresent" @click="setCompetitorPresent(competitor)">{{ $t("meta.presence-status.present") }}</b-badge>
                    <b-badge class="presence-badge selector withdrawn" v-if="d_showPresenceOptions && !competitor.isWithdrawn && competitor.isSelected" @click="setCompetitorWithdrawn(competitor)">{{ $t("meta.presence-status.withdrawn") }}</b-badge>
                    <b-badge class="presence-badge selector forfeited" v-if="d_showPresenceOptions && !competitor.isWithdrawn && !competitor.isForfeited" @click="setCompetitorForfeited(competitor)">{{ $t("meta.presence-status.forfeited") }}</b-badge>
                    <b-badge class="presence-badge selector absent" v-if="d_showPresenceOptions && !competitor.isWithdrawn && !competitor.isForfeited && !competitor.isAbsent" @click="setCompetitorAbsent(competitor)">{{ $t("meta.presence-status.absent") }}</b-badge>
                    <b-badge variant="secondary" v-if="d_showPresenceOptions" @click="d_showPresenceOptions=false"> &gt; </b-badge>
                    <b-badge class="presence-badge" :class="{ 'disabled': d_showPresenceOptions}" @click="d_showPresenceOptions=!d_showPresenceOptions">
                        <span>&lt;&nbsp;&nbsp;&nbsp;</span>
                        {{ $t(`meta.presence-status.${competitor.presenceStatus}`) }}
                    </b-badge>
                </div>
                    <slot name="edition-button-placeholder" competitor="competitor">
                        <div v-if="d_canEditCompetitor">
                            <b-button size="sm" variant="secondary" :disabled="!canCompetitorBeEdited(competitor)" @click="edit(competitor)"><b-icon icon="pencil" /></b-button>
                        </div>
                    </slot>
                    <slot name="deletion-button-placeholder" competitor="competitor">
                        <div v-if="d_canRemoveCompetitor">
                            <b-button size="sm" variant="danger" :disabled="!canCompetitorBeRemoved(competitor)" @click="remove(competitor.competitor_id)"><b-icon icon="trash-fill" /></b-button>
                        </div>
                    </slot>
            </div>
            <div>

                <b-badge class="withdrawn" v-if="competitor.isWithdrawn">Withdrawn</b-badge>
                <b-badge class="forfeited" v-if="competitor.isForfeited">Forfait</b-badge>
                <b-badge class="absent" v-if="competitor.isAbsent">Absent</b-badge>
            </div>
            <div v-if="!competitor.CATEGORY.isIndividual" style="display:flex; justify-content: flex-start; flex-wrap: wrap;">
                <div v-for="member in competitor.members" :key="member.id" class="member">
                    <span>{{ member.firstname }} {{ member.lastname }}</span>&nbsp;&nbsp;
                    <federal-number should-be-competitor :is-federal="COMPETITION.isFederal" v-model="member.LICENSEE" force-edit @edit="edit_licensee(member)"></federal-number>
                </div>
            </div>
        </b-card>
    </card-list-template>
</template>

<style scoped>
    .member{ border: solid 1px var(--ffvlPrimary); margin: 1px; margin-left:0; margin-right: 5px; padding: 2px; padding-top:1px;}
    .member span{ font-weight: bold;}

    .competitor.absent{
        background-color: gainsboro;
        color: darkgrey;
    }
    .competitor.absent .name{ text-decoration: strikethru;} 

    .competitor.withdrawn{
        background-color: gainsboro;
        color: darkgrey;
    } 
    .competitor.withdrawn .name{ text-decoration: strikethru;} 

    .competitor.forfeited{
        background-color: gainsboro;
        color: darkgrey;
    }
    .competitor.forfeited .name{ text-decoration: line-through;} 

    .competitor .presence-badge{ background-color: var(--success); margin-left:3px; margin-right: 3px;}
    .competitor.absent .presence-badge { background-color: #6c757d; }
    .competitor.forfeited .presence-badge { background-color: #6c757d; }
    .competitor.withdrawn .presence-badge { background-color: #6c757d; }
    .competitor .presence-badge.disabled { opacity: 0.5;}

    .competitor .presence-badge.selector { background-color: gainsboro; border: solid 1px #6c757d; color: #6c757d; }
    .competitor .presence-badge.selector.present { border-color: var(--success); color: var(--success); }

</style>