<template>
  <div>
    <data-sync-state v-model="dataSynchronized" data='des compétiteurs de niveau ""' @refresh="refresh">
      <template #data>
        des compétitieurs de niveau "{{ level.name }}"
      </template>
    </data-sync-state>

    <b-tabs card pills :vertical="verticalTabs" v-model="activeTab">
      <b-tab v-for="category in categories" :key="category.code" lazy :title="category.name" @click="showRefresher">
        <template #title>
          <b-badge class="count" v-if="category.subscribers_count > 0" pill :variant="category.effective_competitors_count == 0 ? 'danger' : (category.effective_competitors_count < category.subscribers_count ? 'warning' : 'success')">{{ category.effective_competitors_count }}</b-badge>
          <icon-category v-if="true" variant="dark" v-model="category.code"/>
        </template>
        <competitors-category-tab
          :competition_id="competition_id"
          :level="level.code"
          :category="category.code"
          :lockedForChanges="isLockedForChanges"
          :lockedForRegistration="isLockedForRegistration"
          :lockedForSelection="isLockedForSelection"
          @dataSynchronisationStateChange="categoryTabSynchroStateChange"
          @presentCompetitorsChange="categoryPresentCompetitorsChange"
          @competitorsListChange = "competitorsListChange">
          <template #listHeadCommandLine>
            <slot name="registration-commands"></slot>
            <slot name="levels"></slot>
          </template>
          <template #tab-head-commands>
            <slot name="tab-head-commands"></slot>
          </template>
        </competitors-category-tab>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { RST_COMPETITION,  SEASON_COMPETITION_TYPE_CATEGORY_LEVEL, RST_REF_CATEGORY } from "@/LocalDB";
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';
//import CollapsePanel from '@/components/Panels/CollapsePanel.vue'
import DataSyncState from '@/components/Utilities/DataSyncState.vue';
import CompetitorsCategoryTab from './CompetitorsCategoryTab2.vue';
import IconCategory from '@/components/IconCategory.vue';

export default{
  components: { DataSyncState, CompetitorsCategoryTab/*CollapsePanel*/, IconCategory },
  props:{
    competition_id: { type:String, required: true},
    level: { type:Object, required:true },
    lockedForChanges: {type: Boolean, required: true },
    lockedForRegistration: { type:Boolean, required:true },
    lockedForSelection: { type:Boolean, required: true },
  }, 
  data(){
    return {
      VERBOSE: false,
      
      dataSynchronized: null,
      categories: [],
      activeTab: 0,
      isLockedForChanges: this.lockedForChanges,
      isLockedForRegistration: this.lockedForRegistration,
      isLockedForSelection: this.lockedForSelection,
    };
  },
  computed:{
    verticalTabs(){
      return !this.isSmallWidth;
    }
  },
  methods:{
    async refresh(){
      await this.$showRefresher()
      this.dataSynchronized = await CompetitionsConfigurationHelper.refreshLevelCategories(this.competition_id, this.level.code);
      var compet = RST_COMPETITION.query().where("competition_id", this.competition_id).first();
      var competCategories = RST_REF_CATEGORY.query().where("competition_id", this.competition_id).where("level", this.level.code).get();
      //console.log('refresh', this.dataSynchronized, compet, competCategories);
      this.categories = SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.query()
          .where('year', compet.year)
          .where('competition_type', compet.competition_type)
          .where('level', this.level.code)
          .get()
          .map(item => item.CATEGORY)
          .filter((value, index, self) => { return self.indexOf(value) === index})
          .map(item => {
            var competCategory = competCategories.filter(c => c.category == item.code)[0];
            //console.log('refresh => item/competCategory', item, competCategory);
            return {...item, ...competCategory };
          });
      //console.log('refresh => categories', this.categories);
      this.$hideRefresher();
    },
    async competitorsListChange(){
      if(this.VERBOSE) console.log('competitorsListChange');
      this.$emit('competitorsListChange');
      await this.refresh();
    },
    categoryTabSynchroStateChange(state){
      this.dataSynchronized = state;
    },
    async categoryPresentCompetitorsChange(){
      await this.refresh();
    },
    displayLockState(step){
      if(this.VERBOSE) 
        console.log(`lock state ${step}`, { 
          isLockedForChanges: this.isLockedForChanges, 
          lockedForChanges: this.lockedForChanges,
          isLockedForRegistration: this.isLockedForRegistration,
          lockedForRegistration: this.lockedForRegistration, 
          isLockedForSelection: this.isLockedForSelection,
          lockedForSelection: this.lockedForSelection,
        });
    }
  },
  async mounted(){
    await this.refresh();
    if(this.VERBOSE) this.displayLockState('mounted');
  },
  watch:{
    lockedForChanges: function(newValue){
      this.isLockedForChanges = newValue;
      if(this.VERBOSE) this.displayLockState('lockedForChanges');
    },
    lockedForRegistration: function(newValue){
      this.isLockedForRegistration = newValue;
      if(this.VERBOSE) this.displayLockState('lockedForRegistration');
    },
    lockedForSelection: function(newValue){
      this.isLockedForSelection = newValue;
      if(this.VERBOSE) this.displayLockState('lockedForSelection');
    }  
  }
}
</script>

<style scoped>
  .count{ position:absolute !important; z-index: 10 !important; margin-top:-5px !important; margin-left:-5px !important;}
</style>
